










































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import AdoriService from '@/services/adori'
import { validationMixin } from 'vuelidate'
import ImagePreviewer from '@/mixins/ImagePreviewer'
import PlanCard from '@/components/Payment/PlanCard.vue'
import * as Sentry from '@sentry/vue'

@Component({
  components: { PlanCard },
})
export default class Plans extends Mixins(validationMixin, ImagePreviewer) {
  @Prop(Boolean) isYoutube!: boolean
  @Getter networkId!: string
  @Getter currentNetworkSubscriptions!: any
  @Getter currentNetwork!: any

  @Action getNetworkSubscriptions!: any
  paymentPlans: any = null
  paymentInterval: string = 'MONTH'
  selectedPlanId: string = ''
  hasAlreadyChoosenPlan: boolean = false
  cardStyle!: string
  isLoadingPlans: boolean = false

  get fromYoutube() {
    return this.isYoutube
  }
  get networkMetaAllowed() {
    return this.$permissions.isManageNetworkMetaAllowed()
  }

  get networkType() {
    if (this.currentNetwork) {
      return this.currentNetwork.network.networkType
    }
    return 'SAAS'
  }

  get networkBillingAllowed() {
    return this.$permissions.isManageNetworkBillingAllowed()
  }

  get isStepTwoValid() {
    return this.selectedPlanId
  }

  get monthlyPlans() {
    if (!this.formattedPlans) return []
    return this.formattedPlans.filter((plan: any) => plan['MONTH'] || false).map((plan: any) => plan['MONTH'])
  }

  get yearlyPlans() {
    if (!this.formattedPlans) return []
    return this.formattedPlans.filter((plan: any) => plan['YEAR'] || false).map((plan: any) => plan['YEAR'])
  }

  get selectedIntervalPlans() {
    return this.paymentInterval === 'MONTH' ? this.monthlyPlans : this.yearlyPlans
  }

  get formattedPlans() {
    if (this.paymentPlans) {
      const plans = this.paymentPlans.map((d: any) => {
        const MONTH = d.plans.find((d: any) => d.interval === 'MONTH')
        const YEAR = d.plans.find((d: any) => d.interval === 'YEAR')
        if (MONTH) MONTH.planName = d.name
        if (YEAR) YEAR.planName = d.name
        return { MONTH, YEAR }
      })
      return plans
    }
    return null
  }

  get subscription() {
    const subs = this.currentNetworkSubscriptions
    return subs && subs.subscription
  }

  togglePlanInterval() {
    if (this.paymentInterval === 'MONTH') this.paymentInterval = 'YEAR'
    else this.paymentInterval = 'MONTH'
  }

  async handlePlanDetails() {
    // this.isSubmitting = true
    if (this.isStepTwoValid) {
      try {
        const order: any = await AdoriService.createPaymentOrder(this.networkId, {
          planId: this.selectedPlanId,
          mode: 'PAYMENT',
        })
        const tran: any = await AdoriService.createPaymentTransaction(this.networkId, {
          orderId: order.id,
        })
        await AdoriService.fetchPaymentTransaction(this.networkId, tran.id)
      } catch (error) {
        Sentry.captureException(error)
      }
    }
    // this.isSubmitting = false
  }

  handlePlanSelect(id: string) {
    this.selectedPlanId = id
    this.$emit('selectedPlanId', id)
  }

  async getPlans(type = '') {
    this.isLoadingPlans = true
    this.$emit('loading', true)
    if (!this.paymentPlans) {
      await this.getNetworkSubscriptions()
      const plans = await AdoriService.fetchPaymentPlans(type)
      this.paymentPlans = plans
      this.selectedPlanId = this.subscription
        ? this.subscription.plan.id
        : this.formattedPlans && this.formattedPlans[this.formattedPlans.length - 1][this.paymentInterval].id
      this.handlePlanSelect(this.selectedPlanId)
    }
    this.isLoadingPlans = false
    this.$emit('loading', false)
  }

  async mounted() {
    if (this.networkType === 'YOUTUBE') {
      await this.getPlans('YOUTUBE')
    } else {
      await this.getPlans()
    }
  }

  handleContact() {
    window.open('https://www.adorilabs.com/contact', '_blank')
  }
}
