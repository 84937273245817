var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _vm.showSideNavigation ? _c("TheSideNavigation") : _vm._e(),
          _c(
            "div",
            {
              staticClass: "limiter scrollbar",
              style: !_vm.showSideNavigation ? "padding-left: 0;" : "",
            },
            [
              _c("TheTitle", {
                attrs: { icon: "payments", title: "Plans", large: true },
              }),
              _c(
                "div",
                {
                  staticClass: "w-100",
                  style: !_vm.showSideNavigation ? "padding-left: 0;" : "",
                },
                [
                  _c("Plans", {
                    on: {
                      loading: function (e) {
                        _vm.loadingPlans = e
                      },
                      selectedPlanId: function (id) {
                        _vm.selectedPlanId = id
                      },
                    },
                  }),
                  !_vm.loadingPlans
                    ? _c(
                        "div",
                        { staticClass: "flex justify-end" },
                        [
                          _vm.showAddPaymentMethod || !_vm.subscription
                            ? _c("BaseButtonAddPaymentMethod", {
                                attrs: {
                                  text: !_vm.subscription
                                    ? "Start free trial"
                                    : "",
                                  planId: _vm.selectedPlanId,
                                },
                              })
                            : _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _vm.showEstimate
                                    ? _c("BaseButtonBorder", {
                                        staticClass: "mr3",
                                        attrs: {
                                          text: "Estimate",
                                          onClick: _vm.handleEstimates,
                                        },
                                      })
                                    : _vm._e(),
                                  _c("BaseButtonAddPaymentMethod", {
                                    attrs: {
                                      text: "Update subscription",
                                      planId: _vm.selectedPlanId,
                                    },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }