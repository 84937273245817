




































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class PlanCard extends Vue {
  @Prop(Object) plan!: any
  @Prop(Boolean) selected!: boolean
  @Prop(Boolean) hideSelectButton!: boolean
  @Prop(Boolean) hideFeatures!: boolean
  @Prop(Boolean) hideAmount!: boolean
  @Prop(Boolean) hideDescription!: boolean
  @Prop({ default: 'width: 350px' })
  cardStyle!: string

  get interval() {
    return 'Month'
  }

  get amountStr() {
    let amount = this.plan.amount
    if (this.plan.interval === 'YEAR') {
      amount = amount / 10
    }
    return `$ ${amount} / ${this.interval}`
  }

  get resources() {
    return this.plan.resources.map((resource: any) => resource)
  }
}
