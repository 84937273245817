








































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import Plans from '@/components/Payment/Plans.vue'
import { SUBSCRIPTION_STATUS } from '@/constants/subscription'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    Plans,
  },
})
export default class ViewPlans extends Vue {
  @Getter currentNetworkSubscriptions!: any
  @Getter networkId!: string
  @Getter currentNetworkPaymentCards!: any

  @Action setShowLoader!: Function
  @Action showPaymentEstimates!: Function
  @Action pushNotification!: Function

  loadingPlans: boolean = false
  selectedPlanId: string = ''

  get subscription() {
    const subs = this.currentNetworkSubscriptions
    return subs && subs.subscription
  }

  get allStatus() {
    return SUBSCRIPTION_STATUS
  }

  get subscriptionStatus() {
    return this.subscription && this.subscription.status
  }

  get showAddPaymentMethod() {
    return (
      this.subscriptionStatus !== this.allStatus.trial &&
      (!this.currentNetworkPaymentCards || !this.currentNetworkPaymentCards.length)
    )
  }

  get showEstimate() {
    if (this.subscription && this.subscription.status === SUBSCRIPTION_STATUS.trial) {
      return false
    } else {
      return true
    }
  }

  get showSideNavigation() {
    return (
      this.subscription &&
      (this.subscription.status === this.allStatus.trial || this.subscription.status === this.allStatus.active)
    )
  }

  async handleEstimates() {
    if (this.subscription.plan.id === this.selectedPlanId) {
      this.pushNotification({
        text: `Selected plan is same as your current plan. \
Choose different plan for estimates. test`,
        type: 'ERROR',
      })
    } else this.showPaymentEstimates(this.selectedPlanId)
  }
}
