var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "br2 b--adori-gray bg-adori-medium-gray ba pa3 h-100",
      style: _vm.cardStyle,
    },
    [
      _c("div", { staticClass: "flex items-center justify-between" }, [
        _c("div", { staticClass: "lh-copy" }, [
          _c("div", {
            staticClass: "silver fw5 f4",
            domProps: { innerHTML: _vm._s(_vm.plan.planName || _vm.plan.name) },
          }),
          !_vm.hideAmount
            ? _c("div", {
                staticClass: "white fw5 f4",
                domProps: { innerHTML: _vm._s(_vm.amountStr) },
              })
            : _vm._e(),
          !_vm.hideDescription
            ? _c("div", {
                staticClass: "silver f7",
                domProps: { innerHTML: _vm._s(_vm.plan.description) },
              })
            : _vm._e(),
        ]),
        !_vm.hideSelectButton
          ? _c(
              "div",
              {
                staticClass: "br-pill p-75 ba white f6 dim pointer",
                class: _vm.selected
                  ? "bg-adori-red b--adori-red"
                  : " b--mid-gray",
                on: {
                  click: function ($event) {
                    return _vm.$emit("on-select", _vm.plan.id)
                  },
                },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.selected ? "Selected" : "Select") +
                    "\n    "
                ),
              ]
            )
          : _vm._e(),
      ]),
      !_vm.hideFeatures
        ? _c(
            "div",
            { staticClass: "bt mt2 pt2 b--adori-gray" },
            _vm._l(_vm.resources, function (resource, i) {
              return _c("div", { key: i }, [
                _c("div", { staticClass: "flex white pv1 f6" }, [
                  _c("i", { staticClass: "material-icons mr1 f6" }, [
                    _vm._v("done"),
                  ]),
                  resource.isText
                    ? _c("div", [
                        _vm._v(
                          _vm._s(resource.text) +
                            " " +
                            _vm._s(resource.feature.name)
                        ),
                      ])
                    : _c("div", [
                        resource.isUnlimited
                          ? _c(
                              "div",
                              {
                                attrs: { title: resource.feature.description },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(resource.feature.name) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                attrs: { title: resource.feature.description },
                              },
                              [
                                _vm._v(
                                  _vm._s(resource.limit) +
                                    " " +
                                    _vm._s(resource.feature.name)
                                ),
                              ]
                            ),
                      ]),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }